<template>
  <!--begin::Dashboard-->
  <!--begin::Row-->
  <div class="row g-5 g-xl-8">
    <div class="col-xl-12">
      <div class="card mb-xl-8">
        <!--begin::Body-->
        <div class="card-body">
          <!--begin::Heading-->
          <div class="text-center">
            <!--begin::Title-->
            <div class="card-label fw-bolder fs-3 mb-3">
              {{ $t("modals.pollster.pollDetails") }}
            </div>
            <!--end::Title-->
          </div>
          <!--end::Heading-->
          <!--begin::Form-->

          <!--begin::Card body-->
          <div class="card-body p-9 pt-1 ps-6 pb-1">
            <Form id="poll_create_form" class="form">
              <div class="row mb-2">
                <div class="col-md-4">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label class="form-label fs-6 fw-bolder text-dark">{{
                      $t("modals.pollster.pollTitle")
                    }}</label>
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      v-model="poll.title"
                      class="form-control form-control-md form-control-solid"
                      type="text"
                      name="title"
                      autocomplete="off"
                      :placeholder="$t('modals.pollster.enterTitle')"
                      readonly
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->
                </div>
                <div class="col-md-8">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label class="form-label fs-6 fw-bolder text-dark">{{
                      $t("modals.pollster.desc")
                    }}</label>
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      v-model="poll.description"
                      class="form-control form-control-md form-control-solid"
                      type="text"
                      name="desc"
                      autocomplete="off"
                      :placeholder="$t('modals.pollster.enterDesc')"
                      readonly
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->
                </div>
              </div>
              <div class="row">
                <!--begin::Col-->
                <div class="col-md-6">
                  <div class="fv-row mb-10">
                    <label class="fs-6 fw-bold mb-2">{{
                      $t("modals.pollster.startDate")
                    }}</label>

                    <!--begin::Input-->
                    <div class="position-relative align-items-center">
                      <!--begin::Datepicker-->
                      <Field
                        v-model="startDate"
                        type="text"
                        name="startDate"
                        class="form-control form-control-lg form-control-solid"
                        :placeholder="$t('modals.pollster.enterStartDate')"
                        :min="new Date().toISOString().split('T')[0]"
                        readonly
                      />
                      <!--end::Datepicker-->
                    </div>
                    <!--end::Input-->
                  </div>
                </div>
                <!--end::Col-->

                <!--begin::Col-->
                <div class="col-md-6 fv-row">
                  <label class="fs-6 fw-bold mb-2">{{
                    $t("modals.pollster.endDate")
                  }}</label>

                  <!--begin::Input-->
                  <div class="position-relative align-items-center">
                    <!--begin::Datepicker-->
                    <Field
                      v-model="endDate"
                      type="text"
                      name="endDate"
                      class="form-control form-control-lg form-control-solid"
                      :placeholder="$t('modals.pollster.enterEndDate')"
                      :min="new Date().toISOString().split('T')[0]"
                      readonly
                    />
                    <!--end::Datepicker-->
                  </div>
                  <!--end::Input-->
                </div>
                <!--end::Col-->
              </div>
              <div class="row mb-10">
                <div class="d-flex align-items-center">
                  <label class="form-label me-3 mt-4">
                    {{ $t("modals.pollster.singlePollType") }}:
                  </label>
                  <div>
                    <div
                      class="
                        form-check
                        form-check-custom
                        form-check-solid
                        form-switch
                        mb-2
                        mt-2
                      "
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="poll.isPrivate"
                      />
                      <div class="form-text text-muted ms-4">
                        {{ $t("modals.pollster.singlePollTypeDesc") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row"
                v-for="(question, index) in poll.surveyQuestions"
                :key="index"
              >
                <div class="col-md-7">
                  <!--begin::Input group-->
                  <div class="fv-row">
                    <!--begin::Label-->
                    <label class="form-label fs-6 fw-bolder text-dark">{{
                      $t("modals.pollster.question")
                    }}</label>
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      class="form-control form-control-md form-control-solid"
                      v-model="question.questionText"
                      type="questionText"
                      :name="'question' + index"
                      autocomplete="off"
                      :placeholder="$t('modals.pollster.enterQuestion')"
                      readonly
                    />
                    <!--end::Input-->
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="question" />
                      </div>
                    </div>
                  </div>
                  <!--end::Input group-->
                </div>
                <div class="col-md-5">
                  <!--begin::Input group-->
                  <div class="fv-row">
                    <!--begin::Label-->
                    <label class="form-label fs-6 fw-bolder text-dark">{{
                      $t("modals.pollster.questionType")
                    }}</label>
                    <!--end::Label-->

                    <!--begin::Input-->
                    <Field
                      type="text"
                      :name="'questionType' + index"
                      class="form-control form-control-lg form-control-solid"
                      :value="
                        question.questionType === 'OPEN_ENDED'
                          ? 'Açık Uçlu'
                          : question.questionType === 'SINGLE_CHOICE'
                          ? 'Tek Seçenekli'
                          : 'Çok Seçenekli'
                      "
                      readonly
                    />
                  </div>
                  <!--end::Input group-->
                </div>
                <div class="fv-row mb-4">
                  <!--
                  <div class="col-md-10"> </div>
                  <div class="col-md-2"> </div>-->

                  <div class="col-md-3 ms-3 mt-3">
                    <div
                      v-if="
                        question.questionType === 'SINGLE_CHOICE' ||
                        question.questionType === 'MULTIPLE_CHOICE'
                      "
                    >
                      <label
                        class="fs-7 fw-bold"
                        v-if="question.surveyQuestionOptions.length > 0"
                      >
                        Cevap Seçenekleri
                      </label>
                      <ul>
                        <li
                          v-for="(
                            option, idx
                          ) in question.surveyQuestionOptions"
                          :key="idx"
                        >
                          {{ option.optionText }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
          <!--end::Card body-->
          <!--end::Form-->
        </div>
        <!--end::Body-->
      </div>
    </div>
  </div>
  <!--end::Row-->
  <!--end::Dashboard-->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { useRoute, useRouter } from "vue-router";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import Swal from "sweetalert2";
import { PollDetailModel } from "@/domain/pollster/poll-detail/model/PollDetailModel";

export default defineComponent({
  name: "pollDetail",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  computed: {},
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const isLoading = ref<boolean>(false);
    const router = useRouter();

    const pollId = ref<any>(Number(route.params["id"]));

    const startDate = ref<string>("");
    const endDate = ref<string>("");

    const store = useStore();
    const pollsterController = store.state.ControllersModule.pollsterController;
    const swalNotification = new SwalNotification();

    const poll = ref<PollDetailModel>({
      surveyQuestions: [],
    });

    const formatDate = (dateString) => {
      if (!dateString) return ""; // Eğer tarih boşsa, boş string döndür

      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0"); // Gün
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Ay (0'dan başladığı için 1 ekliyoruz)
      const year = date.getFullYear(); // Yıl

      return `${day}-${month}-${year}`; // gg-aa-yyyy formatında döndür
    };

    const getPoll = async () => {
      isLoading.value = true;

      const pollDetailModel: PollDetailModel = {
        id: pollId.value,
        surveyQuestions: [],
      };

      pollsterController
        .getPollDetail(pollDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(poll.value, response.getValue());
            startDate.value = formatDate(poll.value.startDate);
            endDate.value = formatDate(poll.value.endDate);
          } else {
            if (!poll.value.id) {
              router.push({ name: "polls" });
            }
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const questionTypeLabel = (value?: string): string => {
      switch (value?.toString()) {
        case "OPEN_ENDED":
          return "Açık Uçlu";
        case "SINGLE_CHOICE":
          return "Tek Seçenekli";
        case "MULTIPLE_CHOICE":
          return "Çok Seçenekli";
        default:
          return "";
      }
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.pollster.title"));
      getPoll();
    });

    return {
      questionTypeLabel,
      poll,
      startDate,
      endDate,
    };
  },
});
</script>
