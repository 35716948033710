
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { useRoute, useRouter } from "vue-router";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import Swal from "sweetalert2";
import { PollDetailModel } from "@/domain/pollster/poll-detail/model/PollDetailModel";

export default defineComponent({
  name: "pollDetail",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  computed: {},
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const isLoading = ref<boolean>(false);
    const router = useRouter();

    const pollId = ref<any>(Number(route.params["id"]));

    const startDate = ref<string>("");
    const endDate = ref<string>("");

    const store = useStore();
    const pollsterController = store.state.ControllersModule.pollsterController;
    const swalNotification = new SwalNotification();

    const poll = ref<PollDetailModel>({
      surveyQuestions: [],
    });

    const formatDate = (dateString) => {
      if (!dateString) return ""; // Eğer tarih boşsa, boş string döndür

      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0"); // Gün
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Ay (0'dan başladığı için 1 ekliyoruz)
      const year = date.getFullYear(); // Yıl

      return `${day}-${month}-${year}`; // gg-aa-yyyy formatında döndür
    };

    const getPoll = async () => {
      isLoading.value = true;

      const pollDetailModel: PollDetailModel = {
        id: pollId.value,
        surveyQuestions: [],
      };

      pollsterController
        .getPollDetail(pollDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(poll.value, response.getValue());
            startDate.value = formatDate(poll.value.startDate);
            endDate.value = formatDate(poll.value.endDate);
          } else {
            if (!poll.value.id) {
              router.push({ name: "polls" });
            }
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const questionTypeLabel = (value?: string): string => {
      switch (value?.toString()) {
        case "OPEN_ENDED":
          return "Açık Uçlu";
        case "SINGLE_CHOICE":
          return "Tek Seçenekli";
        case "MULTIPLE_CHOICE":
          return "Çok Seçenekli";
        default:
          return "";
      }
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.pollster.title"));
      getPoll();
    });

    return {
      questionTypeLabel,
      poll,
      startDate,
      endDate,
    };
  },
});
